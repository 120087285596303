@font-face {
    font-family: "Neuropolitical";
    src: local("Neuropolitical"), url("./fonts/neuropolitical_rg.otf");
}

@font-face {
    font-family: "Neon Vortex";
    src: local("Neon Vortex"), url("./fonts/neon_vortex.ttf");
}

@font-face {
    font-family: "Vermin Vibes";
    src: local("Vermin Vibes"),
        url("./fonts/vermin_vibes_rg.woff") format("woff"),
        url("./fonts/vermin_vibes_rg.woff2") format("woff2");
}

body {
    background: black;
    color: #111122;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: 'Neuropolitical';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
    font-family: 'Vermin Vibes';
    font-size: 3em;
}

h2, h3, h4, h5, h6 {
    font-family: 'Neon Vortex';
}

input {
    font-family: 'Neuropolitical';
    font-size: 16px;
    padding: 4px 16px;
    border-radius: 16px;
}

button {
    background: #eeeeee;
    border-color: #111122;
    border-radius: 16px;
    font-family: 'Neuropolitical';
    font-size: 16px;
    padding: 4px 16px;
    flex: 1;
}